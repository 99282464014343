import {CustomerType} from "../../const/customer-type";
import {CooperationStatus} from "../../const/cooperation-status";
import {CooperationType} from "../../const/cooperation-type";
import {Placement} from "../../const/placement";
import {BizStatus} from "../../const/biz-status";
import axiosInstance from "../axios";


export interface ListCustomerReq {
	customerNo?: string,
	companyName?: string,
	customerType?: CustomerType,
	cooperationStatus?: CooperationStatus,
	customerSourceId?: number,
	cooperationType?: CooperationType,
	placement?: Placement,
	bizStatus?: BizStatus,
	directManager?: string,
	bizManagerId?: number,
	cooperationTimeBegin?: string,
	cooperationTimeEnd?: string,
	firstAccountTimeBegin?: string,
	firstAccountTimeEnd?: string,

	pageNumber?: number,
	pageSize?: number,
}

interface CustomerSourceItem {
	id: number,
	name: string,
}

interface BizManagerItem {
	id: number,
	username: string,
}

interface AdvBuyerItem {
	id: number,
	username: string,
}

interface CompanyItem {
	id: number,
	name: string,
	firstAccountTime?: string
}

interface CustomerItem {
	id: number,
	customerNo: string,
	bcNameList: string[],
	cooperationTypeList: CooperationType[],
	placementList: Placement[],
	customerType: CustomerType,
	cooperationStatus: CooperationStatus,
	bizStatus: BizStatus,
	cooperationTime?: string,
	directManager: string,
	customerSource: CustomerSourceItem,
	bizManager: BizManagerItem,
	advBuyer: AdvBuyerItem,
	background: string,
	companyList: CompanyItem[],
}

export interface ListCustomerRes {
	customers: CustomerItem[],
	totalCount: number,
}

export interface AddCustomerReq {
	customerNo: string,
	bcNameList: string[],
	companyNameList: string[],

	cooperationTypeList: CooperationType[],
	placementList?: Placement[],
	customerType?: CustomerType,
	directManager?: string,
	customerSourceId: number,
	bizManagerId: number,
	advBuyerId: number,
	cooperationStatus: CooperationStatus,
	bizStatus: BizStatus,
	background?: string
}

export interface UpdateCustomerReq {
	customerId: number,
	customerNo: string,
	bcNameList: string[],
	companyList: {
		companyId: number | null,
		companyName: string
	}[],

	cooperationTypeList: CooperationType[],
	placementList?: Placement[],
	customerType?: CustomerType | null,
	directManager?: string,
	customerSourceId: number,
	bizManagerId: number,
	advBuyerId: number,
	cooperationStatus: CooperationStatus,
	bizStatus: BizStatus,
	background?: string
}

export const listCustomer = async (params: ListCustomerReq): Promise<ListCustomerRes> => {
	const response = await axiosInstance.get<ListCustomerRes>(
		'/customer/list',
		{
			params
		}
	)
	return response.data;
}

export const addCustomer = async (params: AddCustomerReq): Promise<number> => {
	const response = await axiosInstance.post(
		'/customer/add',
		params
	)
	return response.data;
}

export const updateCustomer = async (params: UpdateCustomerReq): Promise<null> => {
	const response = await axiosInstance.post(
		'/customer/update',
		params
	)
	return response.data;
}

export interface AddLogReq {
	customerId: number,
	remark: string
}

export interface ListLogReq {
	customerId: number,
}

export interface ListLogRes {
	items: {
		logTime: string,
		username: string,
		remark: string
	}[]
}

export const addFollowLog = async (params: AddLogReq): Promise<null> => {
	const response = await axiosInstance.post(
		'/customer/add_follow_log',
		params
	)
	return response.data;
}

export const listFollowLog = async (params: ListLogReq): Promise<ListLogRes> => {
	const response = await axiosInstance.get(
		'/customer/list_follow_log',
		{
			params
		}
	)
	return response.data;
}

export const addSpendLog = async (params: AddLogReq): Promise<null> => {
	const response = await axiosInstance.post(
		'/customer/add_spend_log',
		params
	)
	return response.data;
}

export const listSpendLog = async (params: ListLogReq): Promise<ListLogRes> => {
	const response = await axiosInstance.get(
		'/customer/list_spend_log',
		{
			params
		}
	)
	return response.data;
}

export interface ListUpdateLogReq {
	customerId: number,
}

export interface ListUpdateLogRes {
	items: {
		logTime: string,
		username: string,
		name: string,
		fromValue: string,
		toValue: string,
	}[]
}

export const listUpdateLog = async (params: ListUpdateLogReq): Promise<ListUpdateLogRes> => {
	const response = await axiosInstance.get<ListUpdateLogRes>(
		'/customer/list_update_log',
		{
			params
		}
	)
	return response.data;
}

export interface UpdateCompanyFirstAccountTimeReq {
	companyId: number,
	firstAccountTime: string | null
}

export const updateCompanyFirstAccountTime = async (params: UpdateCompanyFirstAccountTimeReq): Promise<null> => {
	const response = await axiosInstance.post(
		'/customer/update_company_first_account_time',
		params
	)
	return response.data;
}


